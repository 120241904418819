import React from 'react'
import Page from '../layout/Page'
import Content from '../layout/Content'
import styles from './Home.module.css'

function Home() {
  return (
    <Page>
      <section className={styles.section}>
        <Content>
          <h1 className={styles.heading}>The Unicorn club is shut down</h1>
          <p>
            Yesterday 04 Dec 2021, we had a pre-mint for our Unicorn Billionaires Club project. Our team was really excited to see if the buzz we had seen in our Discord could be converted into 100 real club members with an Unicorn NFT as their members card. 
          </p>
          <p>
            Unfortunately, the buzz was not real. Even though we had +1000 discord members and +2000 twitter-followers we didn’t manage to sell A SINGLE Unicorn NFT. What a failure! Therefore, we decided to mint all the 100 NFTs we had created for the pre mint by ourselves. 
          </p>
          <p>
          Since we minted all the NFTs ourselves we feel that it is safe to cancel the project without anyone except from ourselves losing money. And that is also the decision we have made, the Unicorn Billionaires Club is officially shut down. We will not list the project on any secondary market.
          </p>
        </Content>
      </section>
      <section className={styles.section}>
        <Content>
          <h2 className={styles.sectionHeading}>Learnings</h2>
          <p>
            So how could a project with quite many followers not sell a single NFT? There could be many reasons for that but here are some things we’ve learned that maybe could be helpful for others
          </p>
          <ol>
            <li>
              <h5>Don’t do an invite challenge with anything other than your NFT as a reward</h5>
              <p>To attract more people to join our Discord we decided to create an invite challenge with SOL as reward for the ones who invite the most people. Initially we thought that was a good idea but actually it was a big mistake. That only attracted people who were interested in rewards rather than in our project. Our Discord server was filled with bots and fake accounts. We tried to clean it up as good as possible but in the end it seems like many of the +1000 members in the channel were just fake accounts or people who had been tricked to join the channel and had no idea what NFTs or Solana was.</p>
            </li>
            <li>
              <h5>Community is everything</h5>
              <p>Our team consists of the best developers and a world class designer and children's book illustrator. Creating the illustrations, a rarity system, developing the website and metaplex Candy Machine was quite easy for us. It doesn’t matter how great your team is, building a solid community is the hard part and there are no shortcuts for that. We already postponed our mint one time and creating the pre mint was the only way to not have to postpone it again. We really thought that some people were as excited for the project as we were. </p>
            </li>
            <li>
              <h5>Timing</h5>
              <p>The last few weeks we have seen major drops in Solana NFT floor prices and the markets for crypto in general have been a little more red than earlier this year. We have seen many projects that directly after mint are listed on marketplaces for under mint price. That was not the case when we first started our project. We know that this is just the beginning for web3 and NFTs but we cannot wait for the next cycle, it’s just not possible.</p>
            </li>
          </ol>
        </Content>
      </section>
      <section className={styles.section}>
        <Content>
          <h2 className={styles.sectionHeading}>What about the giveaway challenge?</h2>
          <p>When cleaning up our Discord server from obvious bots and fake accounts we could see that the number of leaves for the leading accounts were increasing. And since no one was minting on our pre mint we figured that many of the invites in the challenge were fake. Therefore we have decided to cancel the contest together with the project. We will shut down the Discord server and we will not give away any prices. That was not our intention but it is what it is.</p>
        </Content>
      </section>
      <section className={styles.section}>
        <Content>
          <h2 className={styles.sectionHeading}>Going forward</h2>
          <p>Even though the Unicorn club was a failure for us we have learnt a lot and are coming out of this with loads of new knowledge. Our team will continue to build great things on the Solana blockchain and we are curious to see what will happen with the ecosystem going forward.</p>

          <div className={styles.sincerely}>
            <h6>Sincerely,</h6>
            <p>The Unicorn Billionaires Team</p>
          </div>
        </Content>
      </section>
      
    </Page>
  )
}

export default Home