import React from 'react'
import styles from './Content.module.css'

function Content({ children, ...rest }) {
  return (
    <div {...rest} className={styles.content}>
      {children}
    </div>
  )
}

export default Content